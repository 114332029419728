import React from 'react'
import {LIST_LAYOUT, MOBILE_LIST_LAYOUT} from '@wix/wix-events-commons-statics'
import {useStyles} from '@wix/tpa-settings/react'
import stylesParams from '../../../stylesParams'
import {EmptyState, SingleEvent, Calendar, CompactMobile, Cards, CardsMobile, ListLayout, SideBySide} from './chunks'
import {LayoutProps} from '.'

export const Layout = ({listLayout, singleEventWidget, emptyStateVisible, narrowLayout, mobile}: LayoutProps) => {
  const mobileListLayout = useStyles().get(stylesParams.mobileListLayout)

  if (emptyStateVisible) {
    return <EmptyState />
  }

  if (singleEventWidget) {
    return <SingleEvent />
  }

  if (mobile) {
    if (listLayout === LIST_LAYOUT.CALENDAR) {
      return <Calendar />
    }
    switch (mobileListLayout) {
      case MOBILE_LIST_LAYOUT.COMPACT:
        return <CompactMobile />
      default:
        return <CardsMobile />
    }
  }

  switch (listLayout) {
    case LIST_LAYOUT.GRID:
    case LIST_LAYOUT.NEW_GRID:
      return <Cards />
    case LIST_LAYOUT.SIDE_BY_SIDE:
      return <SideBySide />
    case LIST_LAYOUT.CALENDAR:
      return <Calendar />
    default:
      if (narrowLayout) {
        return <CardsMobile />
      }
      return <ListLayout />
  }
}
